<template>
  <div>
    <div class="PageContain">
      <div class="time-box">
        <el-date-picker
          v-model="time"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
        <el-button
          type="primary"
          @click="addItem"
          style="margin-left: 10px"
          v-if="isAdd"
          >确定添加</el-button
        >
        <el-button
          type="primary"
          @click="reqData"
          style="margin-left: 10px"
          v-if="!isAdd"
          >确定修改</el-button
        >
        <el-button @click="cancleModify" style="margin-left: 10px" v-if="!isAdd"
          >取消</el-button
        >
      </div>
      <div style="padding-top: 10px; width: 98%">
        <el-table
          :data="timeList"
          stripe
          style="width: 100%"
          border
          :height="elementHeight"
        >
          <el-table-column type="index" label="" width="80"> </el-table-column>
          <el-table-column prop="name" label="开始时间">
            <template slot-scope="scope">
              <span>{{ scope.row.fromDate.split("T")[0] }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="结束时间">
            <template slot-scope="scope">
              <span>{{ scope.row.toDate.split("T")[0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <i class="el-icon-edit" @click="editItem(scope.row)"></i>
              <i
                class="el-icon-delete"
                @click="deleteItem(scope.row, scope.$index)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post, put, deleteMethod } from "../../api/http";
export default {
  data() {
    return {
      elementHeight: 0,
      timeList: [],
      time: [],
      isAdd: true,
      rowItem: {},
    };
  },
  mounted() {
    this.getElementHeight();
    this.getTimeList();
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 70);
      });
    },
    getTimeList() {
      get("/api/TimeControl/GetList").then((resp) => {
        if (resp.code == 200) {
          this.timeList = resp.data;
        }
      });
    },
    addItem() {
      this.isAdd = true;
      this.reqData();
    },
    reqData() {
      if (this.time.length == 0) return;
      var startTime = this.time[0];
      console.log(this.time);
      var endTime = this.time[1];
      var user = this.$store.state.userInfo;
      var data = {
        id: this.isAdd ? 0 : this.rowItem.id,
        fromYear: startTime.getFullYear(),
        fromMonth: startTime.getMonth() + 1,
        fromDay: startTime.getDate(),
        fromDate: startTime.toISOString(),
        toYear: endTime.getFullYear(),
        toMonth: endTime.getMonth() + 1,
        toDay: endTime.getDate(),
        toDate: endTime.toISOString(),
        saveUserId: user.saveUserId,
        saveUserNo: user.userNo,
        saveUserName: user.userName,
        saveDate: new Date().toISOString(),
      };
      var methods = this.isAdd ? post : put;
      methods("/api/TimeControl", data)
        .then((resp) => {
          if (resp.code == 200) {
            this.time = [];
            this.$message({
              type: "success",
              message: this.isAdd ? "添加成功" : "修改成功",
            });
            this.isAdd = true;
            this.getTimeList();
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error(this.isAdd ? "添加失败" : "修改失败");
        });
    },
    deleteItem(row, index) {
      this.$confirm("您确认要删除" + row.fromYear + "年的时间监控?")
        .then(() => {
          deleteMethod("/api/TimeControl?Id=" + row.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.timeList.splice(index, 1);
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        })
        .catch(() => {});
    },
    editItem(row) {
      this.isAdd = false;
      this.rowItem = row;
      this.time = [new Date(row.fromDate), new Date(row.toDate)];
    },
    cancleModify() {
      this.isAdd = true;
      this.time = [];
    },
  },
};
</script>

<style lang="less" scoped>
.contain {
  padding-top: 20px;
  width: 300px;
}
.time-box {
  display: flex;
  align-items: center;
  .time-item {
    display: flex;
    align-items: center;
    margin: 15px;
  }
}
.el-range-editor.el-input__inner {
  padding: 0 10px !important;
}
.el-icon-delete {
  color: red !important;
}
.el-icon-edit {
  color: blue !important;
}
.el-table {
  & i {
    border: 1px solid;
    border-radius: 50px;
    padding: 4px;
    margin: 0 20px;
  }
}
</style>